import {
  MapContainer,
  Popup,
  TileLayer,
  GeoJSON,
  LayersControl,
  LayerGroup,
  ZoomControl,
} from "react-leaflet"
import L, { LatLng, LatLngTuple, PathOptions } from "leaflet"
import "leaflet/dist/leaflet.css"
import LocationMarker from "./LocationMarker"
import { GeoJsonObject } from "geojson"
import FlyToLocation from "../components/FlyToLocation"
import { useEffect, useState } from "react"
import { NavBar } from "../components/NavBar"
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router"
import ReactDOMServer from "react-dom/server"
import { Alert } from "react-bootstrap"
import CustomPopup from "../components/CustomPopup"
import Search from "../components/Search"
import { OpenStreetMapProvider } from "leaflet-geosearch"
import { useFeatures } from "../contexts/FeaturesContext"
import OffcanvasFavoritesTable from "../components/OffcanvasFavoritesTable"
import { useFavorites } from "../contexts/FavoritesContext"
import RoutingControl from "../components/RoutingControl"
import { useRouting } from "../contexts/RoutingContext"

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

export const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
}

const BushiesChart = () => {
  const [geoPosition, setGeoPosition] = useState(new LatLng(0, 0))
  const [error, setError] = useState("")
  const [showOffcanvas, setShowOffcanvas] = useState(false)
  const { destinationGeoPosition, startGeoPosition, displayRoute } =
    useRouting()
  const { currentUser, adminUser, logout } = useAuth()
  const { featuresList, setFeatures } = useFeatures()
  const { favoritesList, setFavorites } = useFavorites()
  const history = useHistory()

  useEffect(() => {
    const setFeaturesWrapper = async () => {
      await setFeatures()
    }
    setFeaturesWrapper()

    if (currentUser) {
      const setFavoritesWrapper = async () => {
        await setFavorites(currentUser.uid)
      }
      setFavoritesWrapper()
    }
  }, [setFeatures, setFavorites])

  const flyToCenter = (map: any) => {
    setTimeout(() => {
      map.flyTo(center, 17, { duration: 3 })
    }, 1000)
  }

  const handleLogout = () => {
    setError("")
    try {
      logout()
      history.push("/login")
    } catch {
      setError("Failed to logout")
    }
  }

  const handleShowOffcanvas = () => {
    setShowOffcanvas(true)
  }

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false)
  }

  const center = [-33.933149, 18.62957]

  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        {currentUser ? (
          adminUser ? (
            <NavBar
              brandText="Bushies Chart"
              navObject={{
                dropdown: {
                  dropdownTitle: "Profile Actions",
                  dropdownItems: [
                    { href: "/", text: "Go to dashboard" },
                    { href: "/update-profile", text: "Update your profile" },
                  ],
                },
                link: {
                  texts: ["Admin Page", "Logout", "View Favorites"],
                  hrefs: ["/admin", "", "#"],
                  cb: [() => "", handleLogout, handleShowOffcanvas],
                },
              }}
            />
          ) : (
            <NavBar
              brandText="Bushies Chart"
              navObject={{
                dropdown: {
                  dropdownTitle: "Profile Actions",
                  dropdownItems: [
                    { href: "/", text: "Go to dashboard" },
                    { href: "/update-profile", text: "Update your profile" },
                  ],
                },
                link: {
                  texts: ["Logout", "View Favorites"],
                  hrefs: ["", "#"],
                  cb: [handleLogout, handleShowOffcanvas],
                },
              }}
            />
          )
        ) : (
          <NavBar
            brandText="Bushies Chart"
            navObject={{
              dropdown: {
                dropdownTitle: "Sign Up or Sign In",
                dropdownItems: [
                  { href: "/login", text: "Login" },
                  { href: "/signup", text: "Sign Up" },
                ],
              },
              link: { texts: ["Guest"], hrefs: [""], cb: [] },
            }}
          />
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        <MapContainer
          center={center as LatLngTuple}
          zoom={10}
          scrollWheelZoom={true}
          style={{ width: "100%", height: "90%" }}
          doubleClickZoom={false}
          whenCreated={(map) => {
            flyToCenter(map)
          }}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Search
            provider={new OpenStreetMapProvider()}
            animateZoom={true}
            position="topleft"
            style="button"
          />
          <ZoomControl position="topleft" />
          <LayersControl position="topright" collapsed={true}>
            <LayersControl.Overlay name="Show current event location markers">
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes("Event")
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.bindPopup(
                        ReactDOMServer.renderToString(
                          <CustomPopup
                            name={feature.properties.name}
                            featureType={feature.properties.featureType}
                            images={feature.properties.images}
                            description={feature.properties.name}
                            center={feature.properties.center}
                            key={feature.properties.name}
                          />
                        )
                      )
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Show all 'Help' features">
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes("Help")
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.bindPopup(
                        ReactDOMServer.renderToString(
                          <CustomPopup
                            name={feature.properties.name}
                            featureType={feature.properties.featureType}
                            images={feature.properties.images}
                            description={feature.properties.name}
                            center={feature.properties.center}
                            key={feature.properties.name}
                          />
                        )
                      )
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Show all 'Laboratory' features">
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes(
                        "Laboratory"
                      )
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.bindPopup(
                        ReactDOMServer.renderToString(
                          <CustomPopup
                            name={feature.properties.name}
                            featureType={feature.properties.featureType}
                            images={feature.properties.images}
                            description={feature.properties.name}
                            center={feature.properties.center}
                            key={feature.properties.name}
                          />
                        )
                      )
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Show all 'Landmark' features">
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes("Landmark")
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.bindPopup(
                        ReactDOMServer.renderToString(
                          <CustomPopup
                            name={feature.properties.name}
                            featureType={feature.properties.featureType}
                            images={feature.properties.images}
                            description={feature.properties.name}
                            center={feature.properties.center}
                            key={feature.properties.name}
                          />
                        )
                      )
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Show all 'Restaurant' features">
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes(
                        "Restaurant"
                      )
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.on({
                        mouseover: () => {
                          const featureCenter = feature.properties.center
                          layer.bindTooltip(feature.properties.name)
                          layer.openTooltip(featureCenter as LatLngTuple)
                        },
                        mouseout: () => {
                          layer.unbindTooltip()
                          layer.closeTooltip()
                        },
                      })
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay
              // checked
              name="Show all 'Lecture Hall' features"
            >
              <LayerGroup>
                {featuresList.map((feature) => (
                  <GeoJSON
                    data={feature as GeoJsonObject}
                    pathOptions={feature.properties as unknown as PathOptions}
                    filter={(feature) => {
                      return feature.properties.featureType.includes(
                        "Lecture Hall"
                      )
                    }}
                    key={feature.properties.name}
                    onEachFeature={(feature, layer) => {
                      layer.bindPopup(
                        ReactDOMServer.renderToString(
                          <CustomPopup
                            name={feature.properties.name}
                            featureType={feature.properties.featureType}
                            images={feature.properties.images}
                            description={feature.properties.name}
                            center={feature.properties.center}
                            key={feature.properties.name}
                          />
                        )
                      )
                    }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
          {featuresList.map((feature) => (
            <GeoJSON
              data={feature as GeoJsonObject}
              pathOptions={{ stroke: false, opacity: 0, fillOpacity: 0 }}
              key={feature.properties.name}
              filter={(feature) => {
                return !feature.properties.featureType.includes("Event")
              }}
            >
              <Popup>
                <CustomPopup
                  name={feature.properties.name}
                  featureType={feature.properties.featureType}
                  images={feature.properties.images}
                  description={feature.properties.name}
                  center={feature.properties.center}
                  key={feature.properties.name}
                />
              </Popup>
            </GeoJSON>
          ))}
          <LocationMarker position="bottomleft" />
          <FlyToLocation
            position="bottomright"
            geoPosition={geoPosition}
            setGeoPosition={setGeoPosition}
            buttonText="Go to center of UWC"
            popupFeatureText="The center of UWC"
            positionToFlyTo={center as unknown as LatLng}
            zoom={17}
          />
          {displayRoute && (
            <RoutingControl
              position={"topright"}
              start={startGeoPosition}
              end={destinationGeoPosition}
              color={"#757de8"}
            />
          )}
        </MapContainer>
        {currentUser && (
          <OffcanvasFavoritesTable
            handleClose={handleCloseOffcanvas}
            show={showOffcanvas}
            favoritesList={favoritesList}
          />
        )}
      </div>
    </>
  )
}

export default BushiesChart
