import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import {
  getFirestore,
  collection,
  getDocs,
  Firestore,
  QueryDocumentSnapshot,
  DocumentData,
  QuerySnapshot,
  CollectionReference,
} from "firebase/firestore/lite"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

export const auth: firebase.auth.Auth = app.auth()
export const db: Firestore = getFirestore(app)

export const getUsers = async (db: Firestore) => {
  const usersCol: CollectionReference<DocumentData> = collection(db, "users")
  const usersSnapshot: QuerySnapshot<DocumentData> = await getDocs(usersCol)
  const usersList: DocumentData[] = usersSnapshot.docs.map(
    (doc: QueryDocumentSnapshot<DocumentData>) => doc.data()
  )
  return usersList
}

export default app
