import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

export interface NavObject {
  link: {
    texts: string[]
    hrefs: string[]
    cb: { (): void }[]
  }
  dropdown: {
    dropdownTitle: string
    dropdownItems: DropdownItems[]
  }
}

export interface DropdownItems {
  href: string
  text: string
}

export interface NavBarProps {
  brandText: string
  navObject: NavObject
}

export const NavBar = (props: NavBarProps) => {
  const { brandText, navObject } = props
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>{brandText}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {navObject.link.texts.map((text, index) => (
              <Nav.Link
                as={Link}
                key={index}
                to={navObject.link.hrefs[index]}
                onClick={navObject.link.cb[index] && navObject.link.cb[index]}
              >
                {text}
              </Nav.Link>
            ))}
            <NavDropdown
              title={navObject.dropdown.dropdownTitle}
              id="collasible-nav-dropdown"
            >
              {navObject.dropdown.dropdownItems.map((dropdownItem, index) => (
                <NavDropdown.Item as={Link} key={index} to={dropdownItem.href}>
                  {dropdownItem.text}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
