import {
  DocumentData,
  collection,
  getDocs,
  QuerySnapshot,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore/lite"
import { useContext, createContext, useState, useEffect, useMemo } from "react"
import { db } from "../firebase"
import { cleanGeoJson, featureConverter } from "../utilities/GeoJsonUtilities"

export interface FeaturesContextValue {
  featuresList: DocumentData[]
  setFeatures: () => Promise<DocumentData[]>
  addFeature: (featureToAdd: string) => void
  editFeature: (
    editedFeature: DocumentData,
    featureToEdit: DocumentData
  ) => void
  deleteFeature: (featureToDelete: string) => void
}

const featuresContextEmpty: FeaturesContextValue = {
  featuresList: [],
  setFeatures: () => new Promise((resolve) => resolve),
  addFeature: () => null,
  editFeature: () => null,
  deleteFeature: () => null,
}

const FeaturesContext = createContext(featuresContextEmpty)

export const useFeatures = () => useContext(FeaturesContext)

export const FeaturesProvider = ({ children }: any) => {
  const [featuresList, setFeaturesList] = useState<DocumentData[]>([])
  const [loading, setLoading] = useState(false)

  const addFeature = (featureToAdd: string) => {
    if (featureToAdd.charAt(0) !== "{") {
      featureToAdd = `{${featureToAdd}}`
    }
    const featureToAddParsed = JSON.parse(featureToAdd)
    const cleanedFeature = cleanGeoJson(featureToAddParsed)
    console.log(cleanedFeature)

    cleanedFeature.features.map(async (feature) => {
      await setDoc(doc(db, "uwcFeatures", feature.properties.name), {
        type: feature.type,
        properties: feature.properties,
        geometry: {
          type: feature.geometry.type,
          coordinates: JSON.stringify(feature.geometry.coordinates),
        },
      })
      setFeaturesList((featuresState) => [...featuresState, feature])
    })
  }

  const getFeatures = async () => {
    const featuresSnapshot: QuerySnapshot<DocumentData> = await getDocs(
      collection(db, "uwcFeatures").withConverter(featureConverter)
    )
    const retrievedFeatures: DocumentData[] = featuresSnapshot.docs.map((doc) =>
      doc.data()
    )

    return retrievedFeatures
  }

  const setFeatures = async () => {
    if (featuresList.length === 0) {
      const retrievedFeatures = await getFeatures()
      setFeaturesList(retrievedFeatures)
    }

    return featuresList
  }

  const editFeature = async (
    editedFeature: DocumentData,
    featureToEdit: DocumentData
  ) => {
    await setDoc(doc(db, "uwcFeatures", editedFeature.properties.name), {
      type: editedFeature.type,
      properties: editedFeature.properties,
      geometry: {
        type: editedFeature.geometry.type,
        coordinates: JSON.stringify(editedFeature.geometry.coordinates),
      },
    })
    if (editedFeature.properties.name !== featureToEdit.properties.name) {
      await deleteDoc(doc(db, "uwcFeatures", featureToEdit.properties.name))
    }
    setFeaturesList([])
    await setFeatures()
  }

  const deleteFeature = async (featureToDelete: string) => {
    await deleteDoc(doc(db, "uwcFeatures", featureToDelete))
    setFeaturesList((featuresState) =>
      featuresState.filter((feature) => {
        return feature.properties.name !== featureToDelete
      })
    )
  }

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    if (featuresList.length !== 0) {
      if (isMounted) {
        setFeaturesList(featuresList)
      }
    }
    setLoading(false)
    return () => {
      isMounted = false
    }
  }, [])

  const value: FeaturesContextValue = useMemo(
    () => ({
      featuresList,
      setFeatures,
      addFeature,
      editFeature,
      deleteFeature,
    }),
    [featuresList.length]
  )

  return (
    <FeaturesContext.Provider value={value}>
      {!loading && children}
    </FeaturesContext.Provider>
  )
}
