import { Redirect, Route } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const PrivateAdminRoute = ({ component: Component, ...rest }: any) => {
  const { adminUser } = useAuth()

  return (
    <Route
      {...rest}
      render={(props) => {
        return adminUser ? <Component {...props} /> : <Redirect to="/login" />
      }}
    ></Route>
  )
}

export default PrivateAdminRoute
