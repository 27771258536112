import L from "leaflet"
import { createControlComponent } from "@react-leaflet/core"
import "leaflet-routing-machine"
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"

const createRoutineMachineLayer = ({ start, end, color }: any) => {
  const instance = L.Routing.control({
    waypoints: [start, end],
    collapsible: true,
    autoRoute: true,
    lineOptions: {
      extendToWaypoints: true,
      missingRouteTolerance: 1,
      styles: [
        {
          color,
        },
      ],
    },
  })

  return instance
}

const RoutingControl = createControlComponent(createRoutineMachineLayer)

export default RoutingControl
