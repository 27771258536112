import { FormEvent, useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Spinner } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const UpdateProfile = () => {
  const emailRef: any = useRef()
  const passwordRef: any = useRef()
  const passwordConfirmationRef: any = useRef()
  const { currentUser, updateEmail, updatePassword } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
      return setError("Passwords do not match!")
    } else if (
      emailRef.current.value === "" &&
      passwordRef.current.value === "" &&
      passwordConfirmationRef.current.value === ""
    ) {
      return setError(
        "Please enter at least an email or password to be changed before trying to update your profile!"
      )
    }
    const promises = []
    setLoading(true)
    setError("")
    if (currentUser && emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (currentUser) {
      promises.push(updatePassword(passwordRef.current.value))
    }
    Promise.all(promises)
      .then(() => {
        history.push("/")
      })
      .catch(() => {
        setError("Failed to update account!")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <Card className="w-75" style={{ height: "60%" }}>
          <Card.Body className="d-flex flex-column justify-content-between align-items-stretch">
            <h2 className="text-center mb-4">Update Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form
              className="d-flex flex-column justify-content-between h-100"
              onSubmit={handleSubmit}
            >
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  defaultValue={currentUser?.email || "error"}
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Leave blank to keep this the same"
                />
              </Form.Group>
              <Form.Group id="password-confirmation">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmationRef}
                  placeholder="Leave blank to keep this the same"
                />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 button-primary"
                type="submit"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-3">
          <Link to="/" className="white-link">
            Cancel
          </Link>
        </div>
      </Container>
    </>
  )
}

export default UpdateProfile
