import { GeoSearchControl } from "leaflet-geosearch"
import { useEffect } from "react"
import { useMap } from "react-leaflet"

// make new leaflet element
const Search = (props: any) => {
  const map = useMap()
  const { provider, position, style } = props

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider,
    position,
    style,
  })

  // @ts-ignore
  useEffect(() => {
    map.addControl(searchControl)
    return () => map.removeControl(searchControl)
  }, [])

  return null
}

export default Search
