import {
  DocumentData,
  setDoc,
  doc,
  arrayUnion,
  getDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore/lite"
import { useContext, createContext, useState, useEffect, useMemo } from "react"
import { db } from "../firebase"

export interface FavoritesContextValue {
  favoritesList: DocumentData[]
  addFavorite: (
    favoriteToAddName: string,
    favoriteToAddType: string,
    favoriteToAddCenter: number[],
    userUid: any
  ) => Promise<void>
  setFavorites: (userUid: any) => Promise<DocumentData[]>
  deleteFavorite: (
    favoriteToDeleteName: string,
    favoriteToDeleteType: string,
    favoriteToDeleteCenter: number[],
    userUid: any
  ) => void
}

const favoritesContextEmpty: FavoritesContextValue = {
  favoritesList: [],
  addFavorite: () => new Promise((resolve) => resolve),
  setFavorites: () => new Promise((resolve) => resolve),
  deleteFavorite: () => null,
}

const FavoritesContext = createContext(favoritesContextEmpty)

export const useFavorites = () => useContext(FavoritesContext)

export const FavoritesProvider = ({ children }: any) => {
  const [favoritesList, setFavoritesList] = useState<DocumentData[]>([])
  const [loading, setLoading] = useState(false)

  const addFavorite = async (
    favoriteToAddName: string,
    favoriteToAddType: string,
    favoriteToAddCenter: number[],
    userUid: any
  ) => {
    await setDoc(
      doc(db, "users", userUid),
      {
        favorites_list: arrayUnion({
          name: favoriteToAddName,
          type: favoriteToAddType,
          center: favoriteToAddCenter,
        }),
      },
      { merge: true }
    )
    setFavoritesList((favoritesState) => [
      ...favoritesState,
      {
        name: favoriteToAddName,
        type: favoriteToAddType,
        center: favoriteToAddCenter,
      },
    ])
  }

  const deleteFavorite = async (
    favoriteToDeleteName: string,
    favoriteToDeleteType: string,
    favoriteToDeleteCenter: number[],
    userUid: string
  ) => {
    await updateDoc(doc(db, "users", userUid), {
      favorites_list: arrayRemove({
        name: favoriteToDeleteName,
        type: favoriteToDeleteType,
        center: favoriteToDeleteCenter,
      }),
    })
    setFavoritesList((favoritesState) =>
      favoritesState.filter((feature) => {
        return feature.name !== favoriteToDeleteName
      })
    )
  }

  const getFavorites = async (userUid: any) => {
    const favoritesSnapshot = await getDoc(doc(db, "users", userUid))

    return favoritesSnapshot.data()
  }

  const setFavorites = async (userUid: any) => {
    if (favoritesList.length === 0) {
      const retrievedFavorites = await getFavorites(userUid)

      retrievedFavorites && setFavoritesList(retrievedFavorites.favorites_list)
    }

    return favoritesList
  }

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    if (favoritesList.length !== 0) {
      if (isMounted) {
        setFavoritesList(favoritesList)
      }
    }
    setLoading(false)
    return () => {
      isMounted = false
    }
  }, [])

  const value: FavoritesContextValue = useMemo(
    () => ({
      favoritesList,
      addFavorite,
      setFavorites,
      deleteFavorite,
    }),
    [favoritesList.length]
  )

  return (
    <FavoritesContext.Provider value={value}>
      {!loading && children}
    </FavoritesContext.Provider>
  )
}
