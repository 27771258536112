import { useState } from "react"
import { Alert, Button, Card, Container } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const Dashboard = () => {
  const [error, setError] = useState("")
  const { currentUser, adminUser, logout } = useAuth()
  const history = useHistory()

  const handleLogout = () => {
    setError("")
    try {
      logout()
      history.push("/login")
    } catch {
      setError("Failed to logout")
    }
  }

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <Card className="w-75 h-70">
          <Card.Body className="d-flex flex-column justify-content-between align-items-stretch">
            <h2 className="text-center mb-4">Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <strong>Email:</strong> {currentUser?.email}
            <Link
              to="/update-profile"
              className="btn button-primary w-100 mt-3"
            >
              Update Profile
            </Link>
            {adminUser && (
              <Link to="/admin" className="btn button-primary w-100 mt-3">
                Go to admin page
              </Link>
            )}
            <Link to="/bushies-chart" className="btn button-primary w-100 mt-3">
              Go to the map
            </Link>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-3">
          <Button variant="link" className="white-link" onClick={handleLogout}>
            Log Out
          </Button>
        </div>
      </Container>
    </>
  )
}

export default Dashboard
