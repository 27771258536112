import { Button, Container } from "react-bootstrap"

const NotFoundPage = () => {
  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <h1 className="text-white">404, not found.</h1>
        <Button className="button-primary mt-3" href="/login">
          Go to login page
        </Button>
      </Container>
    </>
  )
}

export default NotFoundPage
