import { useContext, useState, createContext, useEffect } from "react"
import { auth, db } from "../firebase"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router"
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite"

export interface AuthContextValue {
  currentUser: firebase.User | null
  guestUser: boolean
  adminUser: boolean
  handleGuestSignIn: () => void
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => void
  login: (email: string, password: string) => void
  logout: () => void
  checkAdminUser: (user: firebase.User | null) => Promise<boolean>
  resetPassword: (email: string) => void
  updateEmail: (email: string) => void
  updatePassword: (password: string) => void
}

const authContextEmpty: AuthContextValue = {
  currentUser: null,
  guestUser: true,
  adminUser: false,
  handleGuestSignIn: () => {
    console.error("An error occurred with sign in")
  },
  signUp: () => {
    console.error("An error occurred with sign up")
  },
  login: () => {
    console.error("An error occurred with login")
  },
  logout: () => {
    console.error("An error occurred with logout")
  },
  checkAdminUser: () => {
    console.error("An error occured checking for administrators")
    return new Promise((resolve) => {
      return resolve
    })
  },
  resetPassword: () => {
    console.error("An error occurred with resetting password")
  },
  updateEmail: () => {
    console.error("An error occurred with update email")
  },
  updatePassword: () => {
    console.error("An error occurred with update password")
  },
}

const AuthContext = createContext(authContextEmpty)

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null)
  const [guestUser, setGuestUser] = useState(true)
  const [adminUser, setAdminUser] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      const userCreds = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      if (userCreds.user !== null) {
        const usersRef = collection(db, "users")
        await setDoc(doc(usersRef, userCreds.user.uid), {
          email: email,
          first_name: firstName,
          last_name: lastName,
          favorites_list: [],
        })
      }
    } catch (e: unknown) {
      if (typeof e === "string") {
        e.toUpperCase()
      } else if (e instanceof Error) {
        console.error(e.message)
      }
    }
  }

  const login = async (
    email: string,
    password: string
  ): Promise<string | undefined> => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (e: unknown) {
      if (e instanceof Error) {
        return e.message
      }
    }
  }

  const logout = () => {
    try {
      setCurrentUser(null)
      setAdminUser(false)
      setGuestUser(true)
      return auth.signOut()
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const checkAdminUser = async (currentUser: firebase.User | null) => {
    try {
      const emailToSearchFor = currentUser?.email
      const adminsRef = collection(db, "administratorEmails")
      const q = query(adminsRef, where("email", "==", emailToSearchFor))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.docs.length > 0) {
        setAdminUser(true)
      }
      return querySnapshot.docs.length > 0
    } catch (e: unknown) {
      return false
    }
  }

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email)
  }

  const updateEmail = (email: string) => {
    if (currentUser) {
      return currentUser.updateEmail(email)
    }
  }

  const updatePassword = (password: string) => {
    if (currentUser) {
      return currentUser.updatePassword(password)
    }
  }

  const handleGuestSignIn = () => {
    setGuestUser(true)
    history.push("/bushies-chart")
  }

  useEffect(() => {
    let isMounted = true
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && isMounted) {
        setCurrentUser(firebaseUser)
        setGuestUser(false)
        await checkAdminUser(firebaseUser)
      } else if (isMounted) {
        setGuestUser(true)
      }
      setLoading(false)
    })
    return () => {
      isMounted = false
      unsubscribe()
    }
  }, [])

  const value: AuthContextValue = {
    currentUser,
    guestUser,
    adminUser,
    handleGuestSignIn,
    signUp,
    login,
    logout,
    checkAdminUser,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
