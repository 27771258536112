import { useRef, useState } from "react"
import {
  Alert,
  Button,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap"
import { useFeatures } from "../contexts/FeaturesContext"

const AddFeatureModal = (props: any) => {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const textareaRef: any = useRef()
  const { addFeature } = useFeatures()

  const handleAdd = () => {
    try {
      setLoading(true)
      setError("")
      addFeature(textareaRef.current.value)
    } catch (error: unknown) {
      setError(
        "An error occurred, please open the developer tools to investigate..."
      )
      console.error(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Modal.Title id="contained-modal-title-vcenter">
            Add your GeoJson below
          </Modal.Title>
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          Remember to use{" "}
          <a href="http://geojson.io" target="_blank" rel="noreferrer">
            geojson.io
          </a>{" "}
          and change the map on the bottom left to OSM to easily add more
          features!
        </Alert>
        <InputGroup>
          <FormControl
            as="textarea"
            className="h-100"
            rows={10}
            aria-label="textarea"
            ref={textareaRef}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn button-primary mt-3 mb-3 px-4"
          disabled={loading}
          onClick={handleAdd}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Add
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setError("")
            props.onHide()
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddFeatureModal
