import { LatLng } from "leaflet"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { Marker, Popup, useMap } from "react-leaflet"
import { POSITION_CLASSES } from "../containers/BushiesChart"

export interface FlyToLocationProps {
  geoPosition: LatLng
  setGeoPosition: (position: LatLng) => void
  positionToFlyTo: LatLng
  zoom: number
  position: keyof typeof POSITION_CLASSES
  popupFeatureText?: string
  buttonText: string
}

const FlyToLocation = (props: FlyToLocationProps) => {
  const {
    geoPosition,
    setGeoPosition,
    positionToFlyTo,
    zoom,
    position,
    popupFeatureText,
    buttonText,
  } = props
  const [goToLocation, setGoToLocation] = useState(false)
  const parentMap = useMap()
  const locationGetter = async (positionToFlyTo: LatLng) => {
    setGeoPosition(positionToFlyTo)
    parentMap.flyTo(positionToFlyTo, zoom)
    setGoToLocation(!goToLocation)
    await setTimeout(() => {
      setGoToLocation(false)
    }, 4500)
  }
  const button = (
    <Button
      className="button-primary mb-3"
      onClick={() => locationGetter(positionToFlyTo)}
    >
      {buttonText}
    </Button>
  )
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

  return (
    <>
      <div className={positionClass}>
        <div className="leaflet-control">{button}</div>
      </div>
      {goToLocation && popupFeatureText && (
        <Marker position={geoPosition}>
          <Popup>{popupFeatureText}</Popup>
        </Marker>
      )}
    </>
  )
}

export default FlyToLocation
