import { Redirect, Route } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { currentUser } = useAuth()

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/forbidden" />
        )
      }}
    ></Route>
  )
}

export default PrivateRoute
