import { useRef, useState } from "react"
import {
  Alert,
  Button,
  FormControl,
  InputGroup,
  Modal,
  ModalProps,
  Spinner,
} from "react-bootstrap"
import { useFeatures } from "../contexts/FeaturesContext"

export interface EditFeatureModalProps extends ModalProps {
  featuretoedit: any
}

const EditFeatureModal = (props: EditFeatureModalProps) => {
  const { featuretoedit: featureToEdit } = props
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const textareaRef: any = useRef()
  const { editFeature } = useFeatures()

  const handleEdit = (editedFeature: any, featureToEdit: any) => {
    try {
      setLoading(true)
      setError("")
      JSON.parse(JSON.stringify(editedFeature))
      editFeature(editedFeature, featureToEdit)
      setSuccess(`Successfully edited ${featureToEdit.properties.name}`)
    } catch (error: unknown) {
      setSuccess("")
      setError(
        "An error occurred, please open the developer tools to investigate..."
      )
      console.error(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit GeoJSON
          </Modal.Title>
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">{success}</Alert>
        ) : (
          <Alert variant="primary">Make changes to the feature below!</Alert>
        )}
        <InputGroup>
          <FormControl
            as="textarea"
            className="h-100"
            rows={10}
            aria-label="textarea"
            defaultValue={JSON.stringify(featureToEdit, null, 2)}
            ref={textareaRef}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn button-primary mt-3 mb-3 px-4"
          disabled={loading}
          onClick={() => {
            try {
              handleEdit(JSON.parse(textareaRef.current.value), featureToEdit)
            } catch (error: unknown) {
              setSuccess("")
              setError(
                "An error occurred, please open the developer tools to investigate..."
              )
            }
          }}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Confirm Edit
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setError("")
            props.onHide && props.onHide()
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditFeatureModal
