import { LatLng } from "leaflet"
import { Button } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useFavorites } from "../contexts/FavoritesContext"
import { useRouting } from "../contexts/RoutingContext"

export interface CustomPopupProps {
  name: string
  images: string[]
  featureType: string
  center: number[]
  description: string
}

const CustomPopup = (props: CustomPopupProps) => {
  const { name, featureType, center } = props
  const { currentUser } = useAuth()
  const { addFavorite, favoritesList, deleteFavorite } = useFavorites()
  const {
    setDestinationGeoPositionWrapper,
    displayRoute,
    setDisplayRouteWrapper,
  } = useRouting()

  const handleRouting = (center: number[]) => {
    setDestinationGeoPositionWrapper(new LatLng(center[1], center[0]))
    setDisplayRouteWrapper(true)
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h4>{name}</h4>

      <img
        className="d-block w-100"
        src="https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg"
        alt="First slide"
      />

      <p>Type of feature: {featureType}</p>
      {favoritesList.filter((feature) => feature["name"] === name).length >
      0 ? (
        <Button
          onClick={async () =>
            await deleteFavorite(name, featureType, center, currentUser?.uid)
          }
          className="w-75 py-2 button-primary"
        >
          Remove from Favourites
        </Button>
      ) : (
        <Button
          onClick={async () =>
            await addFavorite(name, featureType, center, currentUser?.uid)
          }
          className="w-75 py-2 button-primary"
        >
          Add to Favorites
        </Button>
      )}
      {!displayRoute ? (
        <Button
          onClick={() => handleRouting(center)}
          className="w-75 my-2 py-2 button-primary"
        >
          Give me directions!
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDestinationGeoPositionWrapper(new LatLng(0, 0))
            setDisplayRouteWrapper(false)
          }}
          className="w-75 my-2 py-2 button-primary"
        >
          Stop giving me directions!
        </Button>
      )}
    </div>
  )
}

export default CustomPopup
