import { Alert, Button, Container } from "react-bootstrap"
import { useEffect, useState } from "react"
import FeatureTable from "../components/FeatureTable"
import { NavBar } from "../components/NavBar"
import { useHistory } from "react-router"
import { useAuth } from "../contexts/AuthContext"
import { useFeatures } from "../contexts/FeaturesContext"
import AddFeatureModal from "./AddFeatureModal"
import DeleteFeatureModal from "./DeleteFeatureModal"
import EditFeatureModal from "./EditFeatureModal"

const Admin = () => {
  const [error, setError] = useState("")
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState("")
  const history = useHistory()
  const { logout } = useAuth()
  const { featuresList, setFeatures } = useFeatures()

  useEffect(() => {
    const setFeaturesWrapper = async () => {
      await setFeatures()
    }
    setFeaturesWrapper()
  }, [setFeatures])

  const handleLogout = () => {
    setError("")
    try {
      logout()
      history.push("/login")
    } catch {
      setError("Failed to logout")
    }
  }

  const onRowClickHandler = (featureName: string) => {
    setSelectedFeature(featureName)
  }

  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <NavBar
          brandText="Bushies Chart"
          navObject={{
            dropdown: {
              dropdownTitle: "Profile Actions",
              dropdownItems: [
                { href: "/", text: "Go to dashboard" },
                { href: "/update-profile", text: "Update your profile" },
              ],
            },
            link: {
              texts: ["Go to the map", "Logout"],
              hrefs: ["/bushies-chart", ""],
              cb: [() => "", handleLogout],
            },
          }}
        />
        <Container className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="mt-4">All UWC Features</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          <FeatureTable
            onRowClickHandler={onRowClickHandler}
            featuresList={featuresList}
            selectedFeature={selectedFeature}
            isAdminTable={true}
          />
        </Container>
        <Container className="d-flex justify-content-around align-items-center">
          <Button
            className="btn button-primary mt-3 mb-3 w-25"
            onClick={() => setShowAddModal(true)}
          >
            Add a feature
          </Button>
          <AddFeatureModal
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
          />{" "}
          <Button
            disabled={!selectedFeature}
            className="btn button-primary mt-3 mb-3 w-25"
            onClick={() => setShowEditModal(true)}
          >
            Edit a feature
          </Button>
          <EditFeatureModal
            show={showEditModal}
            onHide={() => {
              setShowEditModal(false)
            }}
            featuretoedit={
              featuresList.filter((feature) => {
                return feature.properties.name === selectedFeature
              })[0]
            }
          />
          <Button
            disabled={!selectedFeature}
            className="btn mt-3 mb-3 w-25"
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete a feature
          </Button>
          <DeleteFeatureModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            featuretodelete={selectedFeature}
            isfavorites={false}
          />
        </Container>
      </div>
    </>
  )
}

export default Admin
