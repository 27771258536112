import { DocumentData } from "firebase/firestore/lite"
import { cloneDeep } from "lodash"

export enum FeatureType {
  LectureHall = "LECTURE_HALL",
  Help = "HELP",
  Recreation = "RECREATION",
}

export interface CustomGeoJson {
  type: string
  features: [
    {
      type: string
      properties: {
        stroke?: boolean
        "stroke-opacity"?: number
        "stroke-width"?: number
        color?: string
        fill?: string
        "fill-opacity"?: number
        fillColor?: string
        "marker-color"?: string
        "marker-size"?: string
        "marker-symbol"?: string
        name: string
        featureType: FeatureType
        center: number[]
        images: string[]
      }
      geometry: {
        type: string
        coordinates: number[][][] | number[][] | number[]
      }
    }
  ]
}
export interface CustomGeoJsonFeature {
  type: string
  properties: {
    stroke?: boolean
    "stroke-opacity"?: number
    "stroke-width"?: number
    color?: string
    fill?: string
    "fill-opacity"?: number
    fillColor?: string
    "marker-color"?: string
    "marker-size"?: string
    "marker-symbol"?: string
    name: string
    featureType: FeatureType
    center: number[]
    images: string[]
  }
  geometry: {
    type: string
    coordinates: number[][][]
  }
}

export const renameKey = (obj: any, oldKey: string, newKey: string) => {
  obj[newKey] = obj[oldKey]
  delete obj[oldKey]
  return obj
}

export const cleanGeoJson = (geoJsonToClean: CustomGeoJson) => {
  const cloneOfGeoJsonToClean = cloneDeep(geoJsonToClean)
  cloneOfGeoJsonToClean.features.map((feature) => {
    feature.properties.stroke = true
    delete feature.properties["stroke-opacity"]
    delete feature.properties["stroke-width"]
    feature.properties.fill &&
      renameKey(feature.properties, "fill", "fillColor")
    feature.properties["fill-opacity"] &&
      renameKey(feature.properties, "fill-opacity", "fillOpacity")
    feature.properties["marker-color"] &&
      renameKey(feature.properties, "marker-color", "markerColor")
    feature.properties["marker-size"] &&
      renameKey(feature.properties, "marker-size", "markerSize")
    feature.properties["marker-symbol"] &&
      renameKey(feature.properties, "marker-symbol", "markerSymbol")

    feature.properties.images = []
    if (typeof feature.geometry.coordinates[0] === "number") {
      feature.properties.center = [
        feature.geometry.coordinates[0],
        // @ts-ignore
        feature.geometry.coordinates[1],
      ]
    } else if (typeof feature.geometry.coordinates[0][0] === "number") {
      feature.properties.center = [
        feature.geometry.coordinates[0][0],
        // @ts-ignore
        feature.geometry.coordinates[0][1],
      ]
    } else {
      feature.properties.center = [
        feature.geometry.coordinates[0][0][0],
        feature.geometry.coordinates[0][0][1],
      ]
    }
  })
  return cloneOfGeoJsonToClean
}

export const appendToGeoJson = (
  geoJsonToAppendTo: CustomGeoJson,
  newGeoJson: CustomGeoJson
) => {
  const cloneOfGeoJsonToAppendTo = cloneDeep(geoJsonToAppendTo)
  const cloneOfNewGeoJson = cloneDeep(newGeoJson)
  cloneOfNewGeoJson.features.map((feature) => {
    cloneOfGeoJsonToAppendTo.features.push(feature)
  })
  return cloneOfGeoJsonToAppendTo
}

// export const writeFile = (
//   fileToWriteTo: string,
//   geoJsonObject: CustomGeoJson
// ) => {
//   fs.writeFile(fileToWriteTo, JSON.stringify(geoJsonObject, null, 2), (err) => {
//     if (err) {
//       console.error(err)
//     }
//   })
// }

export const featureConverter = {
  toFirestore: (feature: CustomGeoJsonFeature) => {
    return {
      type: feature.type,
      properties: feature.properties,
      geometry: {
        type: feature.geometry.type,
        coordinates: JSON.stringify(feature.geometry.coordinates),
      },
    }
  },
  fromFirestore: (snapshot: DocumentData) => {
    const data = snapshot.data()
    return {
      type: data.type,
      properties: data.properties,
      geometry: {
        type: data.geometry.type,
        coordinates: JSON.parse(data.geometry.coordinates),
      },
    }
  },
}
