import { DocumentData } from "@firebase/firestore/dist/lite"
import { useState } from "react"
import { Button, Container, Offcanvas } from "react-bootstrap"
import DeleteFeatureModal from "../containers/DeleteFeatureModal"
import FeatureTable from "./FeatureTable"

export interface OffcanvasFavoritesProps {
  show: boolean
  handleClose: () => void
  favoritesList: DocumentData[]
}

const OffcanvasFavoritesTable = (props: OffcanvasFavoritesProps) => {
  const { show, handleClose, favoritesList } = props
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState("")

  const onRowClickHandler = (featureName: string) => {
    setSelectedFeature(featureName)
  }

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Favorites</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container className="d-flex flex-column justify-content-center align-items-center">
            <FeatureTable
              onRowClickHandler={onRowClickHandler}
              featuresList={favoritesList}
              selectedFeature={selectedFeature}
              isAdminTable={false}
            />
            <Button
              disabled={!selectedFeature}
              className="btn mt-3 mb-3 w-50"
              variant="danger"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete a favorite
            </Button>
            <DeleteFeatureModal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              featuretodelete={selectedFeature}
              isfavorites={true}
            />
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default OffcanvasFavoritesTable
