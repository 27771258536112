import { FormEvent, useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const ForgotPassword = () => {
  const emailRef: any = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions.")
    } catch {
      setError("Failed to reset password.")
    }
    setLoading(false)
  }

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <Card className="w-75 h-30">
          <Card.Body className="d-flex flex-column justify-content-between align-items-stretch">
            <h2 className="text-center mb-4">Password Reset</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Form
              onSubmit={handleSubmit}
              className="d-flex flex-column justify-content-between h-100"
            >
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 button-primary mt-3"
                type="submit"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Reset Password
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/login" className="black-link">
                Login
              </Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-white text-center mt-2">
          Need an account?{" "}
          <Link to="/signup" className="white-link">
            Sign Up
          </Link>
        </div>
      </Container>
    </>
  )
}

export default ForgotPassword
