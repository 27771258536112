import { useState } from "react"
import { Alert, Button, Modal, ModalProps, Spinner } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useFeatures } from "../contexts/FeaturesContext"
import { useFavorites } from "../contexts/FavoritesContext"

export interface DeleteFeatureModalProps extends ModalProps {
  featuretodelete: string
  isfavorites: boolean
}

const DeleteFeatureModal = (props: DeleteFeatureModalProps) => {
  const { featuretodelete: featureToDelete, isfavorites: isFavorites } = props
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const { deleteFeature } = useFeatures()
  const { deleteFavorite, favoritesList } = useFavorites()
  const { currentUser } = useAuth()

  const handleDelete = (featureToDelete: string, isFavorites: boolean) => {
    try {
      setLoading(true)
      setError("")
      if (isFavorites) {
        const favoriteToDelete = favoritesList.find(
          (feature) => feature.name === featureToDelete
        )

        if (favoriteToDelete) {
          deleteFavorite(
            favoriteToDelete.name,
            favoriteToDelete.type,
            favoriteToDelete.center,
            currentUser?.uid
          )
        } else {
          throw new Error("Something went wrong!")
        }
      } else {
        deleteFeature(featureToDelete)
      }
      setSuccess(`Successfully deleted ${featureToDelete}`)
    } catch (error: unknown) {
      setSuccess("")
      setError(
        "An error occurred, please open the developer tools to investigate..."
      )
      console.error(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Modal.Title id="contained-modal-title-vcenter">
            Delete a feature
          </Modal.Title>
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">{success}</Alert>
        ) : (
          <Alert variant="danger">
            You have selected to delete {featureToDelete}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mt-3 mb-3 px-4"
          variant="warning"
          disabled={loading}
          onClick={() => handleDelete(featureToDelete, isFavorites)}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Confirm Delete
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setError("")
            setSuccess("")
            props.onHide ? props.onHide() : undefined
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteFeatureModal
