import { DocumentData } from "firebase/firestore/lite"
import { Table } from "react-bootstrap"

export interface FeatureTableProps {
  featuresList: DocumentData[]
  isAdminTable?: boolean
  onRowClickHandler?: (featureName: string) => void
  selectedFeature?: string
}

const FeatureTable = (props: FeatureTableProps) => {
  const { featuresList, onRowClickHandler, selectedFeature, isAdminTable } =
    props

  return (
    <Table size="sm" hover={true}>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Feature Type</th>
          <th>Center</th>
        </tr>
      </thead>
      <tbody>
        {isAdminTable
          ? featuresList.map((feature: any, index: number) => {
              return (
                <tr
                  onClick={
                    onRowClickHandler
                      ? () => {
                          onRowClickHandler(feature.properties.name)
                        }
                      : undefined
                  }
                  className={
                    selectedFeature === feature.properties.name
                      ? "bg-info border border-dark"
                      : ""
                  }
                  key={`#${index + 1}`}
                >
                  <td key={index + 1}>{index + 1}</td>
                  <td key={feature.properties.name}>
                    {feature.properties.name}
                  </td>
                  <td key={feature.properties.featureType}>
                    {feature.properties.featureType}
                  </td>
                  <td
                    key={feature.properties.center}
                  >{`${feature.properties.center[0].toFixed(
                    5
                  )}, ${feature.properties.center[1].toFixed(5)}`}</td>
                </tr>
              )
            })
          : featuresList.map((feature: any, index: number) => {
              return (
                <tr
                  onClick={
                    onRowClickHandler
                      ? () => {
                          onRowClickHandler(feature.name)
                        }
                      : undefined
                  }
                  className={
                    selectedFeature === feature.name
                      ? "bg-info border border-dark"
                      : ""
                  }
                  key={`#${index + 1}`}
                >
                  <td key={index + 1}>{index + 1}</td>
                  <td key={feature.name}>{feature.name}</td>
                  <td key={feature.type}>{feature.type}</td>
                  <td key={feature.center}>{`${feature.center[0].toFixed(
                    5
                  )}, ${feature.center[1].toFixed(5)}`}</td>
                </tr>
              )
            })}
      </tbody>
    </Table>
  )
}

export default FeatureTable
