import { collection, query } from "@firebase/firestore/lite"
import { getDocs, where } from "firebase/firestore/lite"
import { FormEvent, useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Spinner } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { db } from "../firebase"

const SignUp = () => {
  const firstNameRef: any = useRef()
  const lastNameRef: any = useRef()
  const emailRef: any = useRef()
  const passwordRef: any = useRef()
  const passwordConfirmationRef: any = useRef()
  const { signUp, handleGuestSignIn } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    if ((await searchForUserEmail(emailRef)) === true) {
      setLoading(false)
      return setError("This email address is already in use!")
    } else {
      setLoading(false)
    }

    if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
      return setError("Passwords do not match!")
    } else if (passwordRef.current.value.length < 6) {
      return setError("Passwords must be longer than 6 characters!")
    }

    try {
      setError("")
      setLoading(true)
      await signUp(
        emailRef.current.value,
        passwordRef.current.value,
        firstNameRef.current.value,
        lastNameRef.current.value
      )
      history.push("/")
    } catch (e: unknown) {
      if (typeof e === "string") {
        e.toUpperCase()
      } else if (e instanceof Error) {
        console.error(e.message)
      }
      setError("Failed to create an account")
    }
    setLoading(false)
  }

  const searchForUserEmail = async (emailRef: any) => {
    const emailToSearchFor: string = emailRef.current.value
    const usersRef = collection(db, "users")
    const q = query(usersRef, where("email", "==", emailToSearchFor))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.length > 0
  }

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <Card className="w-75 h-75">
          <Card.Body className="d-flex flex-column justify-content-between align-items-stretch">
            <h2 className="text-center mb-4">Sign Up</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form
              className="d-flex flex-column justify-content-between h-100"
              onSubmit={handleSubmit}
            >
              <Form.Group id="first-name">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" ref={firstNameRef} required />
              </Form.Group>
              <Form.Group id="last-name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" ref={lastNameRef} required />
              </Form.Group>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirmation">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmationRef}
                  required
                />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 mt-3 button-primary"
                style={{
                  fontWeight: "bold",
                }}
                type="submit"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Sign Up
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-3 text-white">
          Already have an account?{" "}
          <Link to="/login" className="white-link">
            Log In
          </Link>
        </div>
        <div className="w-100 text-center mt-3">
          <Button
            variant="link"
            className="white-link"
            onClick={handleGuestSignIn}
          >
            Continue as guest
          </Button>
        </div>
      </Container>
    </>
  )
}

export default SignUp
