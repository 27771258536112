import { Marker, Popup, useMap } from "react-leaflet"
import { LatLng } from "leaflet"
import "leaflet/dist/leaflet.css"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { POSITION_CLASSES } from "./BushiesChart"

export interface LocationMarkerProps {
  position: keyof typeof POSITION_CLASSES
}

const LocationMarker = (props: LocationMarkerProps) => {
  const { position } = props
  const [geoPosition, setGeoPosition] = useState(new LatLng(0, 0))
  const [goToLocation, setGoToLocation] = useState(false)
  const parentMap = useMap()

  const locationGetter = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      setGeoPosition(new LatLng(latitude, longitude))
      parentMap.flyTo(new LatLng(latitude, longitude), parentMap.getZoom())
      setGoToLocation(!goToLocation)
    })
  }

  const button = (
    <Button className="button-primary mb-3" onClick={locationGetter}>
      Go to your location
    </Button>
  )

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

  return (
    <>
      <div className={positionClass}>
        <div className="leaflet-control">{button}</div>
      </div>
      {goToLocation && (
        <Marker position={geoPosition}>
          <Popup>You are here</Popup>
        </Marker>
      )}
    </>
  )
}

export default LocationMarker
