import "../styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../node_modules/leaflet-geosearch/dist/geosearch.css"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import PrivateRoute from "../components/PrivateRoute"
import Dashboard from "./Dashboard"
import SignUp from "./SignUp"
import Login from "./Login"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import BushiesChart from "./BushiesChart"
import PrivateAdminRoute from "../components/PrivateAdminRoute"
import Admin from "./Admin"
import ForbiddenPage from "../components/ForbiddenPage"
import NotFoundPage from "../components/NotFoundPage"
import { FeaturesProvider } from "../contexts/FeaturesContext"
import { FavoritesProvider } from "../contexts/FavoritesContext"
import { RoutingProvider } from "../contexts/RoutingContext"

const App = () => {
  return (
    <>
      <Router>
        <AuthProvider>
          <FeaturesProvider>
            <FavoritesProvider>
              <RoutingProvider>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/signup" component={SignUp} />
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <PrivateRoute
                    path="/update-profile"
                    component={UpdateProfile}
                  />
                  <PrivateAdminRoute path="/admin" component={Admin} />
                  <Route path="/bushies-chart" component={BushiesChart} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/forbidden" component={Login} />
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </RoutingProvider>
            </FavoritesProvider>
          </FeaturesProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
