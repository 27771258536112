import { LatLng } from "leaflet"
import { createContext, useContext, useEffect, useState } from "react"

export interface RoutingContextValue {
  startGeoPosition: LatLng
  setStartGeoPositionWrapper: (latlng: LatLng) => void
  destinationGeoPosition: LatLng
  setDestinationGeoPositionWrapper: (latlng: LatLng) => void
  displayRoute: boolean
  setDisplayRouteWrapper: (newDisplayRouteValue: boolean) => void
}

const routingContextEmpty: RoutingContextValue = {
  startGeoPosition: new LatLng(0, 0),
  setStartGeoPositionWrapper: () => null,
  destinationGeoPosition: new LatLng(0, 0),
  setDestinationGeoPositionWrapper: () => null,
  displayRoute: false,
  setDisplayRouteWrapper: () => null,
}

const RoutingContext = createContext(routingContextEmpty)

export const useRouting = () => useContext(RoutingContext)

export const RoutingProvider = ({ children }: any) => {
  const [startGeoPosition, setStartGeoPosition] = useState(new LatLng(0, 0))
  const [destinationGeoPosition, setDestinationGeoPosition] = useState(
    new LatLng(0, 0)
  )
  const [displayRoute, setDisplayRoute] = useState(false)

  const setStartGeoPositionWrapper = (latlng: LatLng) => {
    setStartGeoPosition(latlng)
  }

  const setDestinationGeoPositionWrapper = (latlng: LatLng) => {
    setDestinationGeoPosition(latlng)
  }

  const setDisplayRouteWrapper = (newDisplayRouteValue: boolean) => {
    setDisplayRoute(newDisplayRouteValue)
  }

  useEffect(() => {
    const success = (geoLocationPosition: any) => {
      const position = geoLocationPosition.coords
      const latitude = position.latitude
      const longitude = position.longitude
      setStartGeoPosition(new LatLng(latitude, longitude))
    }

    const error = (err: unknown) => {
      alert(`Failed with error: ${err}`)
    }

    const options: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    const id = navigator.geolocation.watchPosition(success, error, options)
    return () => {
      console.log("done")

      navigator.geolocation.clearWatch(id)
    }
  }, [])

  const value: RoutingContextValue = {
    startGeoPosition,
    setStartGeoPositionWrapper,
    destinationGeoPosition,
    setDestinationGeoPositionWrapper,
    displayRoute,
    setDisplayRouteWrapper,
  }

  return (
    <RoutingContext.Provider value={value}>{children}</RoutingContext.Provider>
  )
}
