import { FormEvent, useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Spinner } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

const Login = () => {
  const emailRef: any = useRef()
  const passwordRef: any = useRef()
  const { login, handleGuestSignIn } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)

      const loginOutcome: any = await login(
        emailRef.current.value,
        passwordRef.current.value
      )
      if (
        loginOutcome ===
        "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
      ) {
        setLoading(false)
        return setError("A user with these details does not exist!")
      } else if (
        loginOutcome ===
        "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
      ) {
        setLoading(false)
        return setError("Please check the email and password combination!")
      } else if (
        loginOutcome ===
        "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
      ) {
        setLoading(false)
        return setError(
          "This account has temporarily been disabled due to too many failed login attempts! You can immediately restore it by resetting your password or you can try again later."
        )
      }
      history.push("/")
    } catch {
      setError("Failed to log in")
    }
    setLoading(false)
  }

  return (
    <>
      <Container
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          height: "100vh",
          background:
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url("https://project.commongoodfirst.com/wp-content/uploads/2018/01/IMG_1559.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minWidth: "-webkit-fill-available",
        }}
      >
        <Card className="w-75 h-65">
          <Card.Body className="d-flex flex-column justify-content-between align-items-stretch">
            <h2 className="text-center mb-4">Log In</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form
              className="d-flex flex-column justify-content-between h-100"
              onSubmit={handleSubmit}
            >
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" className="mt-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 button-primary mt-3"
                type="submit"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Log In
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/forgot-password" className="black-link">
                Forgot Password?
              </Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-3 text-white">
          Need an account?{" "}
          <Link to="/signup" className="white-link">
            Sign Up
          </Link>
        </div>
        <div className="w-100 text-center mt-3">
          <Button
            variant="link"
            className="white-link"
            onClick={handleGuestSignIn}
          >
            Continue as guest
          </Button>
        </div>
      </Container>
    </>
  )
}

export default Login
